.ExternalLinkSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
}

.iconSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 20vw;
}

.LogoCircle {
    background-color: beige;
    border-radius: 30%;
    padding: 5%;
    margin: 5%;
    height: auto;
    max-width: 50vw;
}

.message {
    font-family: "Cooper Black", sans-serif;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: black;
    color: white;
    font-size: 200%;
    max-width: 90vw;
    margin-top: 5%;
    text-align: center;
}

.Icon {
    max-width: 5vw;
}

@media (max-width: 768px) {
    .Icon {
        max-width: 10vw;
    }
    .iconSection {
        max-width: 90vw;
    }
    .message {
        font-size: 100%;
        max-width: 75vw;
    }
  }
  