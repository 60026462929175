.Home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.LogoContainer {
    max-width: 35%; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.Logo {
    max-width: 100%;
    width: 75%;
    height: auto;
}

.Message {
    margin-bottom: 1%;
}

.MessageImg {
    max-width: 60vw;
}

@font-face {
    font-family: "Cooper Black";
    src: url("../../styles/CooperFiveOpti-Black.otf");
}

@media (max-width: 768px) {

    /* Apply styles for screens with a maximum width of 768px (typical tablet and mobile sizes) */
    .Logo {
        margin-top: 5%;
        height: auto;
        width: 100vw;
    }
    .MessageImg {
        max-width: 95vw;
    }
}