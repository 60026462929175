.gifHead {
    width: auto;
    height: 25vh;
}

.gifSection {
    /* max-height: 100%; */
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {

    /* Apply styles for screens with a maximum width of 768px (typical tablet and mobile sizes) */
    .gifSection {
        flex-direction: row;
    }
    .gifHead {
        height: auto;
        max-width: 35vw;
    }
}