.Video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerPiece {
    width: 90vw; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {

    /* Apply styles for screens with a maximum width of 768px (typical tablet and mobile sizes) */
    .Video .centerPiece{
        flex-direction: column;
    }
}